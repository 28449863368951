import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import {
  Excalidraw,
  Gesture,
  useHandleLibrary,
  exportToClipboard,
  MainMenu,
  exportToBlob
} from "@excalidraw/excalidraw";
import SaveIcon from './save-disk.png';
import ResetIcon from './reset.png';

function App() {
  const [excalidrawInstance, setExcalidrawInstance] = useState(null);
  const [isCollaborating, setIsCollaborating] = useState(false);

  useHandleLibrary({ excalidrawInstance });

  useEffect(() => {
    if (!excalidrawInstance) {
      return;
    }
  }, [])

  const [pointerData, setPointerData] = useState(null);
  // Function to save
  const handleSave = useCallback(async () => {
    if (excalidrawInstance) {
      console.log("excalidrawInstance>>>>", excalidrawInstance)
      const blob = await exportToBlob({
        elements: excalidrawInstance?.getSceneElements(),
        mimeType: "image/png",
        appState: {},
        files: excalidrawInstance?.getFiles()
      });
      let date = new Date().getTime()
      console.log("d>>>>", blob)
      let fileName = `excalidraw-drawing_name_${date}.png`
      var file = new File([blob], fileName, { lastModified: date });
      let formData = new FormData();
      formData.append('file', file);
      let storedToken = window.location.search.split('?token=')[1]
      console.log("storedToken", storedToken)

      axios.post(`https://storage.prepami.com/api/FileUploader/UploadFile2?OriginMS=qbank`, formData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(async response => {
          console.log("response", response?.data)
          sendMessageToParent(response?.data)
        })
        .catch((error) => console.log(error))

      const elements = excalidrawInstance.getSceneElements();
      console.log("Retrieved elements:", elements);

      if (elements && elements.length > 0) {
        toast.success("Scene saved successfully!");
      } else {
        toast.error("No scene elements to save.");
      }
    }
  }, [excalidrawInstance]);

  function sendMessageToParent(data) {
    console.log("data url", data)
    // const data = 'This is the string data from the iframe!';
    // Send message to parent window
    window.parent.postMessage(data, '*'); // Use '*' for simplicity, or restrict it with the parent's domain
  }

  // Function to onChange
  const handleOnChange = (elements) => {
    excalidrawInstance?.updateScene({ elements: elements });
  };

  // Function to reset
  const handleReset = () => {
    excalidrawInstance.resetScene()
  };
  const renderMenu = () => {
    return (
      <MainMenu>
        <MainMenu.DefaultItems.LoadScene />
        <MainMenu.DefaultItems.SaveAsImage />
        <MainMenu.DefaultItems.Export />
      </MainMenu>
    );
  };

  return (
    <div className="App">

      <div className="App-button-row">
        <button className='App-button' onClick={handleSave}>
          <img src={SaveIcon} alt='saveIcon' style={{ marginRight: '5px', width: 24, height: 24 }} />
          Save
        </button>
        <button className='App-button' onClick={handleReset}>
          <img src={ResetIcon} alt='resetIcon' style={{ marginRight: '5px', width: 24, height: 24 }} />
          Reset</button>
      </div>
      <div style={{ height: "565px" }}>
        <Excalidraw
          excalidrawAPI={(api) => setExcalidrawInstance(api)}
          onChange={(elements, state) => {
            // console.info("Elements :", elements, "State : ", state);
            // handleOnChange(elements)
          }}
          onPointerUpdate={(payload) => setPointerData(payload)}
          name="Testing Freekey.ai"
        >
          {renderMenu()}
        </Excalidraw>
      </div>
      <ToastContainer /> {/* Add ToastContainer to display toasts */}
    </div>
  );
}

export default App;
